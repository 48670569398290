<template>
  <b-card class="cache-management">
    <b-row>
      <b-col cols="12" md="6">
        <b-button v-ripple.400 class="mt-1 w-100" @click="clearTranslationsCache">
          {{ $t('cache.translations.clear') }}
        </b-button>
      </b-col>
      <b-col cols="12" md="6">
        <b-button v-ripple.400 class="mt-1 w-100" @click="clearApplicationCache">
          {{ $t('cache.application.clear') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { clearCacheTranslationsRequest, clearCacheAppRequest } from '@/request/globalApi/requests/cacheRequests'

export default {
  name: 'CacheManagement',

  methods: {
    clearTranslationsCache() {
      clearCacheTranslationsRequest()
    },
    clearApplicationCache() {
      clearCacheAppRequest()
    },
  },
}
</script>
