import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/cache'

export const clearCacheRequest = () => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/clear`))

// WARNING /translations/clear should be /translation/clear
export const clearCacheTranslationsRequest = () => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/translations/clear`))

export const clearCacheAppRequest = () => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/app/clear`))

export const clearCacheUserRequest = () => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/user/clear`))
